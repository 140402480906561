<template>
    <div>
        <div v-if="calcFileList.length" class="container" v-infinite-scroll="load" style="overflow:auto">
            <div v-for="item in calcFileList" :key="item[currentModule.moduleId]" class="item">
                <div class="wrap" v-if="item.fileList">
                    <h3 v-if="item.fileList && item.fileList.length">{{ item.title }}</h3>
                    <div class="card-list">
                        <el-card v-for="file in item.fileList" :key="file.id" class="box-card">
                            <div slot="header" class="clearfix" flex="cross:center">
                                <div class="flex_1 m-r-10 text_ellipsis">{{ file.name }}</div>
                            </div>
                            <div class="file" @click="previewFile(file)">
                                <div class="bg"></div>
                                <video v-if="isVideo(file.url)" controls="" name="media">
                                    <source :src="file.url + `?date=${new Date().getTime()}`" type="audio/flac" />
                                </video>
                                <template v-else-if="isPdf(file.url)">
                                    <iframe v-if="!isPad" :src="file.url" frameborder="0"></iframe>
                                </template>
                                <img v-else :src="file.url" width="100%" height="100%" />
                            </div>
                            <avue-form v-model="file.form" :option="calcOption">
                                <template slot="studio" slot-scope="{ value }">
                                    <el-button size="mini" @click="showVideoList(value)">点击录音</el-button>
                                </template>
                                <template slot="reSerial" slot-scope="{ value }">
                                    <div flex>
                                        <el-input v-model="file.form.reSerial" />
                                    </div>
                                </template>
                            </avue-form>
                        </el-card>
                    </div>
                </div>
            </div>
        </div>
        <el-empty v-else description="暂无数据" />
        <div v-show="videoDialog" class="video-dialog" :class="{ 'put-away': videoPutAway }">
            <el-card class="content">
                <div v-if="videoList.length">
                    <div v-for="(video, i) in videoList" :key="i">
                        <p>{{ i }}</p>
                        <video width="250" height="56" controls="" name="media">
                            <source :src="video" type="audio/flac" />
                        </video>
                    </div>
                    <p class="end-line">- - - - - - - - 我是有底线的 - - - - - - - - -</p>
                </div>
                <el-empty v-else description="暂无数据" />
            </el-card>
            <div class="hide-box" @click="videoPutAwayTop">
                <i :class="videoPutAway ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"></i>
            </div>
        </div>
        <el-image v-show="false" ref="elImage" :preview-src-list="srcList" />
    </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { MODULE_DICTID, MODULE_ID } from '@/const/modules/index.js'
export default {
    props: {
        data: {
            type: Array,
            default: () => {
                return [];
            }
        },
        dictId: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            videoDialog: false,
            videoPutAway: false,
            videoList: [],
            detail: true,
            dataList: [],
            num: 4,
            srcList: []
        };
    },
    computed: {
        headers() {
            return {
                Authorization: 'Bearer ' + getToken()
            }
        },
        isPad() {
            return window.innerWidth <= 1200;
        },
        currentModule() {
            const currentDictId = this.dictId;
            const currentModule = Object.entries(MODULE_DICTID).find(([key, value]) => value == currentDictId);
            const [moduleName,] = currentModule;
            const moduleId = MODULE_ID[currentDictId]
            return { moduleName, moduleId }
        },
        photoFormOption() {
            return require(`@/option/photoFormOption`).default(this)
        },
        videoFormOption() {
            return require(`@/option/videoFormOption`).default(this)
        },
        planOption() {
            return require(`@/option/planOption`).default(this)
        },
        materialsOption() {
            return require(`@/option/materialsOption`).default(this)
        },
        calcOption() {
            let option = this.photoFormOption;
            switch (parseInt(this.dictId)) {
                case 5:
                case 6:
                    option = this.planOption;
                    break;
                case 11:
                    option = this.materialsOption
                    break;
                case 12:
                    option = this.photoFormOption
                    break;
                case 13:
                    option = this.videoFormOption;
                    break;
                default:
                    option = this.materialsOption;
                    break;
            }
            option.column.forEach(item => {
                item.detail = true;
            });
            return option;
        },
        calcFileList() {
            let num = 0;
            let arr = [];
            let total = this.dataList.reduce((accumulator, item) => {
                if (item.fileList) {
                    const flattenedArray = accumulator.reduce((acc, curr) => {
                        return acc.concat(curr.fileList);
                    }, []);
                    if (flattenedArray.length >= this.num) return accumulator
                    if (item.fileList.length + accumulator.length < this.num) {
                        num += item.fileList.length
                        accumulator.push(item)
                        return accumulator
                    } else {
                        let lackLength = this.num - accumulator.length
                        let lackkArr = item.fileList.slice(0, lackLength)
                        num += lackLength
                        accumulator.push({
                            ...item,
                            fileList: lackkArr
                        })
                        return accumulator
                    }
                }
            }, arr);
            return total || []
        }
    },
    watch: {
        data: {
            async handler(val) {
                if (this.$parent.name == this.dictId) {
                    this.dataList = val;
                    let keys = this.calcOption.column.map(item => item.prop)
                    this.dataList.forEach(item => {
                        if (item.fileList) {
                            item.fileList.forEach(file => {
                                file.form = {}
                                keys.forEach(itemKey => {
                                    file.form[itemKey] = file[itemKey]
                                })
                            })
                        }
                    })
                }
            }
        }
    },
    methods: {
        load() {
            this.num += 4
        },
        editReSerial(disabled, index, file_i) {
            // dataList[index].imgFileList[file_i]
            const obj = this.findObject(this.calcOption.column, "reSerial");
            const file = this.dataList[index].imgFileList[file_i];
            this.$set(file.form, "detail", disabled);
            obj.detail = !disabled;
            if (obj.detail) {
                this.submitForm();
            }
        },
        preview(file) {
            if (this.isPdf(file.url)) {
                this.toPdf(file.url);
                return;
            }
        },
        toPdf(url) {
            if (this.isPad) {
                this.$confirm("此操作将跳转到第三方打开文件, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        window.uni.postMessage({ data: { action: "openDoc", url: url } });
                    })
                    .catch(() => { });
            } else {
                window.open(url);
            }
        },
        showVideoList(videoList) {
            console.log(JSON.parse(videoList));
            this.videoDialog = true;
            this.videoPutAway = false;
            this.videoList = JSON.parse(videoList);
        },
        videoPutAwayTop() {
            this.videoPutAway = !this.videoPutAway;
        },
        previewFile(file) {
            this.srcList = [file]
            this.$refs.elImage.showViewer = true
        },
    }
};
</script>

<style lang="scss" scoped>
// 滚动
::v-deep .el-form-item__content {
    overflow-x: auto;
    // ie10+
    -ms-overflow-style: none;
    // firefox
    overflow: -moz-scrollbars-none;

    // chrome 和 safari
    &::-webkit-scrollbar {
        display: none;
    }
}

.container {
    overflow-x: auto;

    .item {
        display: flex;
        flex-wrap: wrap;

        .wrap {
            width: 100%;
        }

        .card-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 10px;

            .box-card {
                flex: 0 0 calc(50% - 10px);

                .file {
                    width: 100%;
                    height: 175px;
                    margin-bottom: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    cursor: pointer;

                    .bg {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        display: block;
                        width: 100%;
                        // height: auto;
                    }

                    iframe {
                        width: 100%;
                    }

                    video {
                        width: 100%;
                    }
                }
            }
        }
    }
}

::v-deep .el-card__body {
    padding: 0;
}

.video-dialog {
    z-index: 2000;
    width: calc(50% - 40px);
    position: fixed;
    left: 8px;
    top: 0;
    transition: 0.5s;

    .content {
        position: relative;
        height: 40vh;
        // background: #f1f2f3;
        font-size: 12px;
        padding: 20px;
        padding-left: 30px;
        overflow-y: auto;
    }

    .hide-box {
        position: absolute;
        z-index: 2001;
        font-size: 28px;
    }

    .end-line {
        text-align: center;
        color: #bbb;
        padding: 8px 0;
    }
}

::v-deep .video-dialog {
    .hide-box {
        background: #fff;
        width: 100%;
        text-align: center;
        bottom: -3px;
    }

    &.put-away {
        top: calc(25px - 40vh);
    }
}
</style>
